<script lang="ts" setup></script>

<template>
  <div id="no-auth">
    <el-empty class="display-empty" description="无权限访问" />
  </div>
</template>

<style scoped>
#no-auth {
}

.display-empty {
  //line-height: 60vh;
}
</style>
