/**
 * 权限定义
 */
export const AccessEnum = {
  NOT_LOGIN: "notLogin",
  USER: "user",
  ADMIN: "admin",
};

export default AccessEnum;
